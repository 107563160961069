@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap');

.left-home,
.container-logo-title-description-left-home,
.logo-ivi,
.h3-custom,
.img-ivi-esquerdo,
.termo-adesao {
  display: none;
}

@media screen and (min-width: 1008px) {
  .left-home,
  .container-logo-title-description-left-home,
  .logo-ivi,
  .h3-custom,
  .img-ivi-esquerdo,
  .termo-adesao {
    display: flex;
  }
  .left-home {
    /* border: 1px solid green; */
    width: 50vw;

    height: 100vh;
    padding: 2rem;
    background-color: #fff;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    transition: padding 0.5s ease;
  }
  /* .container-left-home {
    border: 1px solid yellow;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  } */
  .container-logo-title-description-left-home {
    /* border: 1px solid purple; */

    /* display: flex; */
    flex-direction: column;
    gap: 1rem;
  }

  .logo-ivi {
    width: 94px;
    z-index: 999;
  }

  .h3-custom {
    color: var(--Primary-Black, #000);
    font-family: 'Bricolage Grotesque';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .img-ivi-esquerdo {
    /*   border: 1px solid blue; */
    /* min-width: 15vw; */
    /* max-width: 35vw; */
    width: 68%;
    transition: width 0.5s ease;

    margin: 0 auto;
  }

  /* .container-img {
    margin: 0 auto;
    border: 1px solid blue;
  } */

  .termo-adesao {
    /* border: 1px solid blue; */

    color: var(--Secondary-Light-Grey, #727272);
    font-family: 'Kumbh Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

@media screen and (min-width: 1440px) {
  .left-home {
    padding: 4rem;
  }
  .img-ivi-esquerdo {
    /* border: 1px solid red; */
    width: 60%;
  }
  /* .img-ivi-esquerdo { */
  /*   border: 1px solid blue; */
  /* min-width: 15vw; */
  /* max-width: 35vw; */
  /* width: 60%;
    transition: width 0.5s ease;

    margin: 0 auto; */
  /* } */
}
