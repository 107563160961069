.card-home-two {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container-contract {
  /* margin-top: 60px; */
  width: 220px;
  display: flex;
  justify-content: center;
}
