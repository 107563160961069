@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");

.middle-content-registration-complet {
  /* border: 1px solid red; */
  width: 100%;
  /* max-width: 704px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.title-registration-complet {
  /* border: 1px solid blue; */
  font-size: 24px;
  font-weight: 700;
  font-family: "Bricolage Grotesque";
  margin: 1rem 0;
}

.description-registration-complet {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* height: 133px; */
}

.description-registration-complet > p {
  font-family: "Bricolage Grotesque";
}

.arrow {
  margin: 20px;
  transform: rotate(0.25turn);
}

@media screen and (min-width: 1008px) {
  .middle-content-registration-complet {
    /* border: 1px solid red; */
    width: 80%;
    /* max-width: 704px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .title-registration-complet {
    font-size: 24px;
    font-weight: 700;
    font-family: "Bricolage Grotesque";
  }

  .description-registration-complet {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 133px;
    max-width: 576px;
  }

  .arrow {
    transform: none;
  }
}
