@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");
.container-all-form-cnpj {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  transition: all 0.5s ease;
}
.container-createHolderCNPJ {
  /* border: 1px solid blue; */
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  margin: 0.5rem 0;
  transition: all 0.5s ease;
}

.inputs-cnpj-razao-social {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  /* margin: 0.5rem 0; */
  gap: 1rem;
  transition: all 0.5s ease;
  /* transition: gap 0.5s ease; */
}
.input-cnpj-block {
  /* border: 1px solid blue; */
  width: 280px;
  display: flex;
  flex-direction: column;

  border-radius: 14px;
  background: hsla(0, 0%, 93%, 1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0);
  transition: all 0.5s ease;
}

.container-form-Createcnpj {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.5s ease;
  /* margin: 0.5rem 0; */
  /* height: 70%; */
}
/* .container-form-cnpj {
  border: 1px solid blue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 60vh;
} */

.container-input-next-ui-cnpj {
  width: 280px;
  background: var(--Primary-White, #fff);
  border-radius: 14px;
  height: 56px;
  margin: 0.2rem 0;
}

.error-mgs-create-cnpj {
  font-family: "Kumbh Sans";
  font-size: 12px;
  font-weight: 400;
  color: red;
  width: 260px;
}

.button-container-create-cnpj {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  margin: 0.5rem 0;
}

@media screen and (min-width: 641px) {
  .container-all-form-cnpj {
    /* border: 1px solid green; */
    justify-content: center;
    /* justify-content: space-evenly; */
  }
  .container-createHolderCNPJ {
    /* border: 1px solid green; */
    height: 61%;
    transition: all 0.5s ease;
  }
  /* .inputs-cnpj-razao-social {
    border: 1px solid green;
  } */
  .container-form-Createcnpj {
    /* border: 1px solid green; */
    height: 60%;
    transition: all 0.5s ease;
  }
}

@media screen and (min-width: 820px) {
  .container-createHolderCNPJ {
    /* border: 1px solid blue; */
    height: 60%;
    justify-content: space-around;
    transition: all 0.5s ease;
  }
  .inputs-cnpj-razao-social {
    /* border: 1px solid green; */
    flex-direction: row;
  }
  .container-form-Createcnpj {
    /* border: 1px solid blue; */
    height: 80%;
    overflow-y: auto;
    transition: all 0.5s ease;
  }
  .button-container-create-cnpj {
    margin: 0;
  }
}

@media screen and (min-width: 1008px) {
  .container-all-form-cnpj {
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    transition: all 0.5s ease;
    /* justify-content: center; */

    align-items: center;
  }
  .inputs-cnpj-razao-social {
    width: 100%;
    transition: all 0.5s ease;
    /* flex-direction: column; */
  }
  .container-createHolderCNPJ {
    /* border: 1px solid blue; */
    overflow-y: auto;
    height: 100%;
    transition: all 0.5s ease;
  }
  .error-mgs-create-cnpj {
    font-size: 13px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .container-form-Createcnpj {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    transition: all 0.5s ease;
  }
}

@media screen and (min-width: 1180px) {
  .inputs-cnpj-razao-social {
    /* border: 1px solid purple; */
    flex-direction: row;
    width: 100%;
  }

  .container-form-Createcnpj {
    /* border: 1px solid purple; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 80%;
  }
}

@media screen and (min-width: 1366px) {
  .inputs-cnpj-razao-social {
    /* border: 1px solid red; */
    flex-direction: row;
    width: 95%;
    gap: 1rem;
  }
}

@media screen and (min-width: 1500px) {
  .container-all-form-cnpj {
    /* border: 1px solid blue; */
    height: 90%;
    justify-content: center;

    padding: 1rem 0 2rem 0;
  }
  .inputs-cnpj-razao-social {
    /* border: 1px solid purple; */
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    gap: 1rem;
  }
  .container-createHolderCNPJ {
    /* border: 1px solid green; */
    height: 80%;
  }
  .container-form-Createcnpj {
    /* border: 1px solid blue; */
    height: 80%;
  }
}
@media screen and (min-width: 767px) and (min-height: 1023px) {
  .container-all-form-cnpj {
    height: 100%;
    justify-content: center;
  }
  .inputs-cnpj-razao-social {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  /* .container-createHolderCNPJ {
    height: 100%;
  } */
  .container-form-Createcnpj {
    height: 100%;
  }
}

/* @media screen and (min-width: 800px) and (min-height: 790px) {
  .container-all-form-cnpj {
 

    max-height: 90%;
    justify-content: center;

  }
  .inputs-cnpj-razao-social {
  
    flex-direction: row;

    width: 100%;
  }
  .container-createHolderCNPJ {

    max-height: 90%;
    padding: 1rem;
  }
  .container-form-Createcnpj {

    max-height: 75%;
  }
} */
